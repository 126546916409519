
import {computed, defineComponent} from "vue";
import EntityView from "@/components/base/common/EntityView.vue";
import {LoadBaseModal, LoadEntity, LoadPanel, LoadTagModal} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import InvoiceStatus from "@/views/invoice/InvoiceStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import MenuWatch from "@/views/metaEntity/MenuWatch.vue";
import WatchAlert from "@/views/metaEntity/WatchAlert.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import InvoiceService from "@/core/services/InvoiceService";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import InvoiceDuplicate from "@/views/invoice/InvoiceDuplicate.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormMoveInvoice from "@/views/invoice/FormMoveInvoice.vue";
import OriginalInvoice from "@/views/invoice/OriginalInvoice.vue";
import StatementName from "@/views/invoice/StatementName.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import EntityProcessList from "@/views/process/EntityProcessList.vue";

export default defineComponent({
  name: "Invoice",
  components: {
    EntityProcessList,
    TagEntities,
    MenuLink,
    StatementName,
    OriginalInvoice,
    FormMoveInvoice,
    BaseModal,
    InvoiceDuplicate,
    EntityLoading,
    WatchAlert,
    MenuWatch, CurrencyFormat, TabNavigate, QuickAction, DateTimeFormat, InvoiceStatus, EntityView
  },
  setup: function () {
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Invoice Details', [
        {link: true, router: '/claims/' + invoice.value?.claim?.id, text: 'View Claim'},
        {link: false, router: '', text: 'View Invoice'}
      ])
    })
    return {
      entityLoading,
      invoice,
      ...LoadEntity(Actions.LOAD_INVOICE),
      ...LoadPanel(),
      ...LoadBaseModal(),
      ...LoadTagModal(),
    }
  },
  methods: {
    onFastPay() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.fastPay(this.invoice.id).then(() => {
        store.dispatch(Actions.LOAD_INVOICE, this.invoice.id)
      }).finally(() => {
        Swal.close();
      })
    },
    buildNavItem(invoice) {
      const data = [
        {id: 'main-overview', router: `/invoices/` + invoice.id, name: 'Overview'},
        {id: 'invoiceActivity', router: `/invoices/` + invoice.id + '/activities', name: 'Activities'},
        {id: 'invoiceDocument', router: `/invoices/` + invoice.id + '/documents', name: 'Documents'},
        {id: 'invoiceTasks', router: `/invoices/` + invoice.id + '/tasks', name: 'Tasks'},
        {id: 'invoiceProcess', router: `/invoices/` + invoice.id + `/processes`, name: 'Processes'},
      ]
      if (invoice?.adjudication?.status === 'ADJUDICATED' || invoice?.adjudication?.status === 'PAID') {
        data.push({id: 'invoicePayment', router: `/invoices/` + invoice.id + '/payments', name: 'Payments'})
      }
      return data;
    },
    onDownloadPdf(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportPdf(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },

    onDownloadEob(id, cheque: boolean) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportEOB(id, cheque).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onDownloadRepriced(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportRepriced(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    move() {
      const base = this.$refs.moveInvoiceRef as typeof BaseModal;
      base.showBaseModal()
    },
    onMoved() {
      this.reloadData()
      const base = this.$refs.moveInvoiceRef as typeof BaseModal;
      base.hideBaseModal()
    },
    onFinalize() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.finalize(this.invoice.id).then(() => {
        this.reloadData()
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },

    onActivate() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.activate(this.invoice.id).then(() => {
        this.reloadData()
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    removeDuplicate(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.removeDuplicate(id).then(() => {
        this.reloadData()
      }).finally(() => {
        Swal.close();
      })
    }
  }
})
